import React, { useEffect } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import {
	ContentToRichText,
	formatDate,
	getPostImages,
	firstBlockIsScrollyTelling,
} from 'libs/content';
import { useCheckPreviousSibling } from 'context/useCheckPreviousSibling';
import { fadeIn } from 'libs/animations';
import { usePopup } from 'context/PopupProvider';
import ImageWithPopUp from 'parts/image/ImageWithPopUp';
import { setCrawlingDirectives, setSchemaSettings } from 'libs/seo';
import { BreadTextStyle, ImageWrapperStyle } from 'libs/SharedStyling';
import GetComponent, { setComponentProps } from 'libs/components';
import Layout from 'components/layout';
import SeoContentful from 'components/seo/seo-contentful';
import Breadcrumbs from 'components/breadcrumbs';
import TagsAndDate from 'components/blog/TagsAndDate';
import MaxWidth from 'layouts/max-width';
import MediumWidth from 'layouts/medium-width';
import Popup from 'components/popup/Popup';
import SlickSlider from 'components/slick-slider/SlickSlider';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import Spacing from 'layouts/Spacing';
import RelatedContent from 'parts/related-content/RelatedContent';

const MainContent = styled.section`
	animation-name: ${fadeIn};
	animation-duration: 500ms;
	animation-delay: 500ms;
	animation-fill-mode: forwards;
	opacity: 0;
`;

const ContentWrapper = styled(MediumWidth)`
	${BreadTextStyle}
`;

const ImageWrapper = styled.div`
	${ImageWrapperStyle}
`;

export default function BlogPostTemplate({ location, data, pageContext }) {
	const post = data.contentfulInnholdInnlegg || {};
	const relatedPosts = data.allContentfulInnholdInnlegg?.nodes || [];

	const { images, setImages } = usePopup();

	// Adds a class to all 'figure' elements within a specified 'div' that have a previous sibling in the DOM.
	useCheckPreviousSibling('breadtext');

	// Check if first content-block is scrolly-telling
	const isFirstScrollyTelling = firstBlockIsScrollyTelling(post);

	// Get all images in post content and flatten into one array
	const allImages = getPostImages(post);

	// Define page settings
	const pagesettings = {
		transparentheader: isFirstScrollyTelling?.toString() || 'false',
		headertextcolor: (isFirstScrollyTelling && 'white') || 'black',
	};

	// Update state with all images
	useEffect(() => {
		if (!allImages?.length > 0) return;
		setImages(allImages);
		//eslint-disable-next-line
	}, [allImages?.length > 0]);

	useEffect(() => {
		const figures = document.querySelectorAll('figure');
		for (var i = 0; i < figures.length; i++) {
			const figure = figures[i];
			if (!figure?.previousSibling) return;
			figure.classList.add('has-previous-element');
		}
	}, []);

	// Get current popup image
	const currentPopupImage = images?.find(img => img?.current);

	return (
		<Layout location={location} pagesettings={pagesettings}>
			{!isFirstScrollyTelling && (
				<>
					{/* ONLY SHOW BREADCRUMBS IF HAS NO SCROLLY-TELLING INTRO */}
					<Breadcrumbs
						data={pageContext.breadcrumb}
						location={location}
						currentTitle={post?.title}
					/>
					{/* DEFAULT INTRO */}
					<section>
						<TitleAndText
							title={post?.title}
							text={undefined}
							headinglevel="h1"
							isfirstheading={true}
							componentindex={0}
							settings={['Midtstilt tekst']}
							data-cy="blogpost-title"
							spacing={{ bottom: 'large' }}>
							<>
								{post?.intro && (
									<ContentToRichText data={post.intro} />
								)}
								{post?.group?.length > 0 && (
									<TagsAndDate
										tags={post?.group
											?.filter(g => g?.visibility)
											?.map(g => g?.title)}
										align="center"
										date={post?.date}
										style={{
											marginTop: '30px',
											marginBottom: '0',
										}}
									/>
								)}
							</>
						</TitleAndText>
					</section>
					<section>
						<MaxWidth>
							{post?.image && (
								<ImageWrapper>
									<ImageWithPopUp
										image={post?.image}
										altText={post?.image?.description}
										ariaLabel={post?.image?.description}
										spacing={{
											top: 'none',
											bottom: 'large',
										}}
									/>
								</ImageWrapper>
							)}
						</MaxWidth>
					</section>
				</>
			)}

			<MainContent id="breadtext">
				{/* CONTENT BLOCKS */}
				{post?.contentblocks?.length > 0 &&
					post.contentblocks.map((block, index) => {
						// If block is undefined then return nothing and log error
						if (!block || !block?.__typename) {
							console.error('Block is not defined', post);
							return null;
						}

						const contentType = block?.__typename;
						const id = `${contentType}-${block?.id}`;

						// If has image and first block is not scrollytelling
						if (post?.image && !isFirstScrollyTelling) {
							index++;
						}

						// Get component props
						const componentProps = setComponentProps(
							block,
							post?.contentblocks,
							index
						);

						// Set component index
						componentProps.componentindex = index;

						return (
							<section
								id={id}
								key={`${id}-${index}`}
								data-cy={contentType}
								className={componentProps?.sectionclass}>
								<GetComponent
									date={formatDate(post?.date)}
									imagePopUps={true}
									pagesettings={{
										...location,
										...pagesettings,
									}}
									{...componentProps}
								/>
							</section>
						);
					})}

				{/* MAIN CONTENT */}
				{post?.content && (
					<section>
						<MaxWidth>
							<Spacing>
								<ContentWrapper>
									<ContentToRichText data={post.content} />
								</ContentWrapper>
							</Spacing>
						</MaxWidth>
					</section>
				)}

				{/* RELATED POSTS */}
				{relatedPosts?.length > 0 && (
					<section>
						<RelatedContent
							relatedContent={relatedPosts}
							perrow={3}
							layout="Rutenett"
							showTags={true}
							settings={['Skjul tittel og intro']}
						/>
					</section>
				)}
			</MainContent>

			{/* POPUP WITH IMAGE SLIDER */}
			<Popup
				id="image-slider"
				title={`Se bildet "${currentPopupImage?.title}" i full størrelse`}
				entry={post?.title}
				padding="false"
				gradient="true"
				closeButton="white"
				size="default">
				{currentPopupImage?.file?.url && (
					<SlickSlider
						images={allImages}
						current={{
							...currentPopupImage,
							index:
								allImages?.findIndex(
									img =>
										img?.file?.url ===
										currentPopupImage?.file?.url
								) || 0,
						}}
						iconcolors="white"
						fade={true}
						arrows={true}
						infinite={true}
						showcaption="true"
						ariaLabel={`Bildeslider med totalt ${allImages?.length} bilder`}
						layout="large"
						load="instant"
						fit="pad"
					/>
				)}
			</Popup>
		</Layout>
	);
}

export function Head({ data, location, pageContext }) {
	const post = data.contentfulInnholdInnlegg || {};

	return (
		<SeoContentful
			location={location}
			data={post}
			preventCrawling={setCrawlingDirectives(post?.synlighet || null)}
			breadcrumbs={pageContext?.breadcrumb}
			schema={setSchemaSettings(post?.synlighet || null)}
			schemaType="Article"
		/>
	);
}

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!, $gruppe: [String]) {
		contentfulInnholdInnlegg(slug: { eq: $slug }) {
			id
			title: tittel
			date: dato
			updatedAt
			image: bilde {
				id
				contentful_id: contentful_id
				title
				description
				file {
					url
					fileName
					contentType
					details {
						size
					}
				}
				width
				height
			}
			intro: beskrivelse {
				raw
				references {
					__typename
					... on Node {
						... on ContentfulKomponentKnapp {
							...KnappQuery
						}
					}
				}
			}
			synlighet {
				id
				tittel
				innholdstype
				forhindreIndeksering
				beskrivelse {
					childMarkdownRemark {
						rawMarkdownBody
					}
				}
				bilde {
					title
					description
					file {
						url
					}
					width
					height
				}
			}
			content: innhold {
				raw
				references {
					__typename
					... on Node {
						... on ContentfulAsset {
							...ContentfulAssetQuery
						}
						... on ContentfulKomponentKnapp {
							...KnappQuery
						}
						... on ContentfulKomponentSkjema {
							...SkjemaQuery
						}
						... on ContentfulSideNormal {
							...SideNormalQuery
						}
						... on ContentfulInnholdInnlegg {
							...InnholdInnleggQuery
						}
						... on ContentfulKomponentStickyBilderOgTekst {
							...StickyBilderOgTekstQuery
						}
					}
				}
			}
			contentblocks {
				... on ContentfulKomponentBilde {
					__typename
					contentful_id
					...BildeQuery
				}
				... on ContentfulKomponentBildeOgInnhold {
					__typename
					contentful_id
					...BildeOgInnholdQuery
				}
				... on ContentfulKomponentInnhold {
					__typename
					contentful_id
					...InnholdQuery
				}
				... on ContentfulKomponentRelatertInnhold {
					__typename
					contentful_id
					...RelatertInnholdQuery
				}
				... on ContentfulKomponentVideo {
					__typename
					contentful_id
					...VideoQuery
				}
				... on ContentfulKomponentScrollytelling {
					__typename
					id
					contentful_id
					sys {
						contentType {
							sys {
								id
							}
						}
					}
					title
					subtitle
					images {
						... on ContentfulKomponentBilde {
							__typename
							id
							title: bildeTekst
							image: bilde {
								description
								width
								height
								file {
									url
								}
							}
						}
						... on ContentfulKomponentBildeOgInnhold {
							__typename
							id
							title: tittel
							image: bilde {
								description
								width
								height
								file {
									url
								}
							}
						}
					}
					mainImage {
						id
						title
						description
						width
						height
						file {
							url
						}
					}
					settings
					spacing {
						bottom
					}
				}
			}
			group: gruppe {
				id
				title: navn
				slug
				visibility: synlighet
			}
		}
		allContentfulInnholdInnlegg(
			filter: {
				gruppe: {
					elemMatch: {
						navn: { in: $gruppe, nin: ["Guide", "Akademiet"] }
					}
				}
				synlighet: {
					id: { ne: null }
					forhindreIndeksering: { eq: null }
				}
				dato: { ne: "null" }
				slug: { ne: $slug }
			}
			limit: 3
			sort: { dato: DESC }
		) {
			nodes {
				id
				internal {
					type
				}
				title: tittel
				slug
				date: dato
				image: bilde {
					title
					description
					file {
						url
					}
					width
					height
				}
				intro: beskrivelse {
					raw
				}
				content: innhold {
					raw
				}
				group: gruppe {
					id
					title: navn
					visibility: synlighet
				}
			}
		}
	}
`;
